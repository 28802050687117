<!--
 * @Author: wangpeng 306466972@qq.com
 * @Date: 2024-04-22 15:21:39
 * @LastEditors: wangpeng 306466972@qq.com
 * @LastEditTime: 2024-05-09 18:00:24
 * @FilePath: \万博项目\git\新华社项目\新建文件夹\se-opinion-app\src\views\components\echarts\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- background: #FFFFFF; -->

    <div class="echarts_box" :style="{ background: props.background }">
        <div class="echart" :id="id" ref="echartsBox">
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, nextTick, onMounted, watch } from "vue"
import * as echarts from 'echarts';

//   defineProps({
//     id: String,
// })
// const props = defineProps(['id', 'option','background'])

const props = defineProps({
    // 设置默认值
    id: {
        type: String,
    },
    option: {
        type: Object,
    },
    background: {
        type: String,
        default: '#fff'
    }
});

let myChart = ref()
let option = reactive(props.option)
const showtable = () => {
    myChart.value = echarts.init(document.querySelector('#' + props.id));
    // 绘制图表
    myChart.value.setOption(option)

  

    //echarts点击事件监听画布
    // myChart.value.getZr().on('click', function (params) {
    //     console.log(params); // 获取点击事件的参数
    //     // 根据参数完成点击操作
    // });
}
watch(props.option, (user) => {
    nextTick(() => {
        showtable()
    })

},
    { immediate: true, deep: true }
);
onMounted(() => {
    // showtable()

    nextTick(() => {
        // showtable()
    })


})





</script>

<style lang="less" scoped>
.echarts_box {
    border-radius: 8px;
    // box-shadow: 0px 6px 10px 0px #E5ECFF;

    width: 100%;
    height: 100%;

    .echart {
        width: 100%;
        height: 100%;
    }
}
</style>